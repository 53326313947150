import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appEnleyLinks]',
})
export class EnleyLinksDirective {
  @HostListener('click', ['$event'])
  onClick(e: MouseEvent) {
    if (e.button !== 0 || e.ctrlKey || e.shiftKey || e.altKey || e.metaKey) {
      return true;
    }

    const a = getAnchor(e);
    if (a === null) {
      return true;
    }

    if (a.target && a.target !== '_self') {
      return true;
    }

    const url = new URL(a.href);
    if (url.hostname !== 'www.enley.com') {
      return true;
    }
    this.router.navigateByUrl(url.pathname);
    return false;
  }

  constructor(private router: Router, private el: ElementRef) {}
}

function getAnchor(e: MouseEvent): HTMLAnchorElement | null {
  return getAnchorFromComposedPath(e) || getAnchorFromTargets(e);
}

function getAnchorFromComposedPath(e: MouseEvent): HTMLAnchorElement | null {
  if (!e.composed) return null;

  const path = e.composedPath();
  return path.find(isAnchor) || null;
}

function isAnchor(el: any): el is HTMLAnchorElement {
  return el instanceof HTMLAnchorElement;
}

function getAnchorFromTargets(e: MouseEvent): HTMLAnchorElement | null {
  const lastChild = targetElement(e.target);
  const root = targetElement(e.currentTarget);
  if (root instanceof HTMLAnchorElement) {
    return root;
  }

  let current = lastChild;
  while (current !== root && current !== null) {
    if (current instanceof HTMLAnchorElement) {
      return current;
    }
    current = current.parentElement;
  }
  return null;
}

function targetElement(target: EventTarget | null): HTMLElement | null {
  if (target instanceof HTMLElement) {
    return target;
  }
  return null;
}
