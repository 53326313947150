import {DjangoErrorHandler} from 'src/app/django.error';
import {
  Component,
  HostListener,
  Inject,
  InjectionToken,
  OnInit,
  Optional,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {Router, ActivationEnd} from '@angular/router';
import {UsuarioServices} from 'src/app/services/usuario.services';
import {User} from 'src/app/models/user';
import {Product} from 'src/app/models/products';
import {ProductsServices} from 'src/app/services/products.services';
import {AlertService} from 'src/app/services/notificaciones.service';
import {filter, map} from 'rxjs/operators';
import {Notificacion as NotificationModel} from '../../models/notificacion';
import {UtilsServices} from 'src/app/services/utils.services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [UsuarioServices, ProductsServices, UtilsServices],
})
export class HeaderComponent implements OnInit {
  public isLoggedIn: boolean;
  public isAdmin: boolean;
  public error500: boolean;
  public products: Product[];
  public name_initials: string;
  public hasPassword: boolean;
  public showMenu: boolean = false;
  public showProductDropdown: boolean = false;
  public showBlogDropdown: boolean = false;

  public showNotifications: boolean = false;
  public notifications: NotificationModel[] = [];
  public notificationsCounter: number;

  constructor(
    private loginservice: AuthService,
    private userService: UsuarioServices,
    private router: Router,
    private djangoError: DjangoErrorHandler,
    private productService: ProductsServices,
    private alertService: AlertService,
    private utilsService: UtilsServices
  ) {}

  ngOnInit(): void {
    this.productService.getAvailableProducts().subscribe((response) => {
      this.products = response;
    });
    this.alertService.alerts.subscribe((notificationsres) => {
      this.notifications = notificationsres.sort((x, y) =>
        x.created_at > y.created_at ? -1 : 1
      );
      this.notificationsCounter = this.notifications.filter(
        (notification) => !notification.viewed
      ).length;
    });
    this.loginservice.isUserLoggedIn.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      if (isLoggedIn) {
        this.getInitials();
        this.alertService.getAlerts().subscribe();
      }
    });
    this.loginservice.isLoggedIn();

    this.djangoError.is500error.subscribe((error) => (this.error500 = error));

    this.router.events.subscribe(() => {
      this.error500 = false;
      this.showMenu = false;
    });
  }

  private getInitials() {
    this.userService.getUser().subscribe((user) => {
      this.isAdmin = user.is_staff;
      this.hasPassword = user.has_password || user.has_social_access;
      if (user.first_name && user.last_name) {
        var first_initial = user.first_name.charAt(0);
        var second_initial = user.last_name.charAt(0);
      } else if (user.first_name && !user.last_name) {
        var first_initial = user.first_name.charAt(0);
        var second_initial = user.first_name.charAt(1);
      } else if (user.email) {
        var first_initial = user.email.charAt(0);
        var second_initial = user.email.charAt(1);
      }
      this.name_initials = (first_initial + second_initial).toUpperCase();
    });
  }

  logout() {
    this.loginservice.logout().subscribe(
      (resp) => {
        this.isLoggedIn = this.loginservice.isLoggedIn();
        this.router.navigate(['/login']);
      },
      (err) => {
        this.loginservice.doLogoutUser();
      }
    );
  }

  closePopUpServerError() {
    this.djangoError.is500error.next(false);
  }

  deleteNotificacion(notificationId, contractId) {
    this.alertService.deleteAlert(notificationId, contractId).subscribe();
  }

  showAlerts(event) {
    this.notifications
      .filter((notification) => !notification.viewed)
      .map((notification) => {
        var copy = Object.assign({}, notification);
        copy.viewed = true;
        this.alertService.updateAlert(copy).subscribe();
      });

    event.stopPropagation();
    this.showNotifications = !this.showNotifications;
  }
  dontToggle(event) {
    event.stopPropagation();
  }

  @HostListener('document:click') hideOnClick() {
    if (this.showNotifications) {
      this.showNotifications = false;
    }
  }
}
