<section id="ofrecemos">
  <div id="top" style="display: flex; justify-content: center">
    <h2 class="title">La revolución digital de los servicios legales</h2>
  </div>
  <!-- Productos Carrousel -->
  <div
    id="carouselExampleControlsProducts"
    class="carousel slide"
    data-bs-ride="carousel"
    data-interval="false"
  >
    <div class="carousel-inner cardsSize">
      <ng-container *ngIf="innerWidth < 1025">
        <div
          *ngFor="let product of products; let i = index"
          class="carousel-item"
          [ngClass]="[i === 0 ? 'active' : '']"
        >
          <ng-container
            [ngTemplateOutlet]="card"
            [ngTemplateOutletContext]="{ product: product, i: i }"
          >
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="innerWidth >= 1025">
        <ng-container *ngFor="let product of products; let i = index">
          <div
            *ngIf="i % 3 === 0"
            class="cards-div"
            [ngClass]="[i === 0 ? 'active' : '']"
            style=""
          >
            <div
              *ngFor="
                let product of products | slice : i : i + 3;
                let i = index
              "
              class="card-div-margin"
            >
              <ng-container
                [ngTemplateOutlet]="card"
                [ngTemplateOutletContext]="{
                  product: product,
                  i: i,
                  display: 'inline-block'
                }"
              >
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleControlsProducts"
      role="button"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleControlsProducts"
      role="button"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </a>
  </div>
</section>

<ng-template #card let-i="i" let-product="product" let-display="display">
  <span
    class="card cardSize"
    [ngStyle]="innerWidth >= 1025 ? { float: 'left', margin: '0px 5px' } : ''"
  >
    <div class="card-body">
      <a [routerLink]="['/', product.url]">
        <h3
          class="productsTitle"
          [innerHtml]="product.product_description.title_html"
          style="
            margin-bottom: -1.5rem !important;
            width: 240px;
            display: inline-block;
          "
        ></h3>
      </a>
      <p
        class="textStyle1"
        [innerHtml]="product.product_description.description_html"
      >
        <br /><br />
      </p>
      <div
        [innerHtml]="product.product_description.product_description_image"
      ></div>
      <div class="overflow-image">
        <img
          class="imageHeader"
          src="{{ product.product_description_image }}"
          alt="{{ product.product_description.product_description_image_alt }}"
          loading="lazy"
          style="height: 110px"
        />
      </div>
    </div>
  </span>
</ng-template>
