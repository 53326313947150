import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/models/products';
import { ProductsServices } from '../../services/products.services';

@Component({
  selector: 'app-products-description',
  templateUrl: './products-description.component.html',
  styleUrls: ['./products-description.component.css'],
  providers: [ProductsServices],
})
export class ProductsDescriptionComponent implements OnInit {
  public products: Product[];
  public innerWidth: any;

  constructor(
    private _productsv: ProductsServices,
    private _router: Router,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private router: Router
  ) {}

  ngOnInit(): void {
    this._productsv.getAvailableProducts().subscribe((result) => {
      this.products = result;
      this.products = this.products.filter((product) => product.url);
    });
    if (isPlatformBrowser(this._platformId)) {
      this.innerWidth = window.innerWidth;
    }
  }
  @HostListener('window:resize', ['$event'])
  onesize(event) {
    this.innerWidth = window.innerWidth;
  }

  redirect(url) {
    this._router.navigate(['/' + url]);
  }
}
