import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {BlogPost} from 'src/app/models/blog-post';
import {environment} from 'src/environments/environment';
import {globalApi} from './global';

@Injectable({
  providedIn: 'root',
})
export class BlogRssService {
  constructor(private _http: HttpClient) {}

  getAllBlogPosts(): Observable<BlogPost[]> {
    return this._http.get<BlogPost[]>(globalApi.url + 'blog-posts');
  }

  getProductPosts(product: string): Observable<BlogPost[]> {
    return this.getAllBlogPosts().pipe(
      map((blogPosts: BlogPost[]) => {
        return blogPosts
          .map((blogPost: BlogPost) => {
            var url = new URL(blogPost.link);
            url.hostname = environment.frontUrl;
            blogPost.link = url.href;
            return blogPost;
          })
          .filter((post) => product === 'Random' || post.product === product);
      }),
      map((filteredPosts: BlogPost[]) => {
        if (product === 'Random') {
          const filteredPostsProduct = filteredPosts.filter(
            (post) => post.product !== ''
          );
          const shuffledPosts = this.shuffleArray(filteredPostsProduct);
          return shuffledPosts.slice(0, 10);
        }
        return filteredPosts;
      })
    );
  }

  shuffleArray(array: any[]): any[] {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  }
}
