import {CommonModule} from '@angular/common';
import {NgModule, Type} from '@angular/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {AngularMaterialModule} from '../angular-material.module';
import {CheckBoxComponent} from '../components/checkbox/checkbox.component';
import {CustomPhoneComponent} from '../components/custom-phone/custom-phone.component';
import {FormButtonComponent} from '../components/form-button/form-button.component';
import {FormInputComponent} from '../components/form-input/form-input.component';
import {FormSelectComponent} from '../components/form-select/form-select.component';
import {InputRadioComponent} from '../components/input-radio/input-radio.component';
import {InputTextComponent} from '../components/input-text/input-text.component';
import {MutuaSelectComponent} from '../components/mutua-select/mutua-select.component';
import {PopupComponent} from '../components/pop-up/pop-up.component';
import {ToggleSwitchComponent} from '../components/toggle-switch/toggle-switch.component';
import {OrdinalFemPipe, OrdinalPipe} from '../contracts/custom.pipe';
import {RegistrationAddressComponent} from '../shared/registration-address/registration-address.component';
import {AddressComponent} from './address/address.component';
import {EnleyLinksDirective} from './enley-links.directive';
import {InformationComponent} from './information/information.component';
import {TransferspaymentsComponent} from './transferspayments/transferspayments.component';
import {DatosUsuarioComponent} from './usuario/datos-usuario/datos-usuario.component';
import {UsuarioComponent} from './usuario/usuario.component';
import {
  dniCheckDirective,
  maxDateValidatorDirective,
  MaxValidatorDirective,
  minDateValidatorDirective,
  MinValidatorDirective,
  inputInSetValidatorDirective,
} from './validators.directive';
import {StreetTypeDirective} from './address/street-type.directive';
import {PdfWrapperComponent} from './pdf-wrapper/pdf-wrapper.component';
import {AddressReactiveComponent} from './address-reactive/address/address-reactive.component';
import {AddressReactiveJuridicalComponent} from './address-reactive/juridical/juridical-address.component';
import {DisableMousewheelScrollDirective} from './disable-mousewheel-scroll.directive';
import {FloatingWhatsappComponent} from '../components/floating-whatsapp/floating-whatsapp.component';
import {LoadingSpinnerComponent} from './loading-spinner/loading-spinner.component';
import {InvoicePaymentPriceComponent} from './invoice-payment-price/invoice-payment-price.component';
import {InvoiceConfirmationComponent} from './invoice-confirmation/invoice-confirmation.component';
import {ProvinceTypeDirective} from './address/province-type.directive';
import {FloatingButtonContractComponent} from '../components/floating-button-contract/floating-button-contract.component';

const COMPONENTS: Type<any>[] = [
  AddressComponent,
  AddressReactiveComponent,
  AddressReactiveJuridicalComponent,
  CheckBoxComponent,
  CustomPhoneComponent,
  DatosUsuarioComponent,
  FormButtonComponent,
  FormInputComponent,
  FormSelectComponent,
  InformationComponent,
  InputRadioComponent,
  InputTextComponent,
  MutuaSelectComponent,
  PopupComponent,
  RegistrationAddressComponent,
  ToggleSwitchComponent,
  TransferspaymentsComponent,
  UsuarioComponent,
  PdfWrapperComponent,
  FloatingWhatsappComponent,
  FloatingButtonContractComponent,
  LoadingSpinnerComponent,
  InvoicePaymentPriceComponent,
  InvoiceConfirmationComponent,
];

const DIRECTIVES: Type<any>[] = [
  dniCheckDirective,
  EnleyLinksDirective,
  maxDateValidatorDirective,
  MaxValidatorDirective,
  minDateValidatorDirective,
  MinValidatorDirective,
  inputInSetValidatorDirective,
  DisableMousewheelScrollDirective,
];

const PIPES: Type<any>[] = [OrdinalFemPipe, OrdinalPipe];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    StreetTypeDirective,
    ProvinceTypeDirective,
    PdfWrapperComponent,
    FloatingWhatsappComponent,
    FloatingButtonContractComponent,
  ],
  imports: [
    AngularMaterialModule,
    AutocompleteLibModule,
    CommonModule,
    FormsModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    NgSelectModule,
  ],
  exports: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
})
export class SharedModule {}
