import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import {Big} from 'big.js';
import {DjangoErrorHandler} from 'src/app/django.error';
import {BillingDetails} from 'src/app/models/billing-details';
import {JuridicalPerson, PhysicalPerson} from 'src/app/models/constitution';
import {Contrato} from 'src/app/models/contrato';
import {
  Invoice,
  paymentDateSameMonth,
  paymentDateTwoDaysBeforeEnd,
  TransferPayment,
  StoredGlobalCard,
  Cost,
} from 'src/app/models/payment';
import {Address, NationalId, User} from 'src/app/models/user';
import {BillingDetailsService} from 'src/app/services/billing-details.service';
import {ConstitutionService} from 'src/app/services/constitution.services';
import {ContratoService} from 'src/app/services/contrato.services';
import {PaymentServices} from 'src/app/services/payments/payments.services';
import {TrackingService} from 'src/app/services/tracking.services';
import {UsuarioServices} from 'src/app/services/usuario.services';
import {UtilsServices} from 'src/app/services/utils.services';
import {forkJoin, of, throwError} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';

@Component({
  selector: 'app-transferspayments',
  templateUrl: './transferspayments.component.html',
  styleUrls: ['./transferspayments.component.css'],
  providers: [
    PaymentServices,
    ContratoService,
    TrackingService,
    UsuarioServices,
    UtilsServices,
    ConstitutionService,
  ],
})
export class TransferspaymentsComponent implements OnInit, OnChanges {
  public transferPayment: TransferPayment;
  public transferPayments: TransferPayment[];

  public loading: boolean = true;
  @Input() showPopup: Boolean;
  @Input() idContractInput: number;
  @Input() productId: Number;
  @Input() callMethod: Boolean = true;

  @Input() showPopupResume: Boolean;
  @Input() method: any;
  @Input() is_staff: boolean;
  //In order to implement two-way binding, you can add an @Output property with the same name followed by Change, and emit the event when the change occurs:
  @Output() showPopupChange = new EventEmitter<Boolean>();
  @Output() showPopupResumeChange = new EventEmitter<Boolean>();
  @ViewChild('form', {static: false}) form: NgForm;
  @ViewChild('userForm', {static: false}) userForm: NgForm;
  @ViewChild('companyForm', {static: false}) companyForm: NgForm;
  @ViewChild('formTemplate', {static: false}) formTemplate: NgForm;

  @Input() showPopUpUser: Boolean;
  @Output() showPopUpUserChange = new EventEmitter<Boolean>();

  public idContract: number;
  public budget: String;
  public totalPayed: String;
  public changeBudget: boolean = false;
  public user: User;
  public payment_id: string;
  public invoice: Invoice;
  public is_invoice_return: boolean;
  public company: JuridicalPerson;
  public contract: Contrato;
  public billingDetails: BillingDetails;
  public paymentType: string = null;
  public paymentCard: StoredGlobalCard = null;
  public creditCards: StoredGlobalCard[];
  public textPayment: string;
  public showGenericPopUp: boolean = false;
  public disbursements = [];
  public alreadyAddedDisbursements = [];

  constructor(
    private paymentServices: PaymentServices,
    private _route: ActivatedRoute,
    private djangoError: DjangoErrorHandler,
    private contratoService: ContratoService,
    private _trackingsv: TrackingService,
    private _usersv: UsuarioServices,
    private _utilssv: UtilsServices,
    private _constitutionsv: ConstitutionService,
    private billingDetailsService: BillingDetailsService
  ) {
    this.transferPayment = new TransferPayment();
    this.totalPayed = '0';
    this.user = new User();
    this.user.nationalid = new NationalId();
    this.user.customer.address = new Address();
    this.company = new JuridicalPerson();
    this.company.registered_office = new Address();
    this.invoice = new Invoice();
  }

  ngOnInit(): void {
    this._route.params.subscribe((params: Params) => {
      this.idContract = params.idContract || this.idContractInput;
      this.getContract();
    });

    if (this.method == undefined) {
      this.transferPayment.outPayment = true;
    }
  }

  getPreviousDisbursements() {
    this.paymentServices
      .getConfiguredDisbursements(this.contract.id)
      .subscribe((response) => {
        this.alreadyAddedDisbursements = response['disbursements'];
        this.loading = false;
      });
  }

  getContract() {
    if (this.idContract != undefined) {
      this.contratoService
        .getContrato(this.idContract)
        .subscribe((contractResp) => {
          this.contract = contractResp;
          this.getTransferPayment();
          this.getUser();
          this.getCompanyConstitution();
          this.getPreviousDisbursements();
        });
    }
  }

  getBillingDetails() {
    this.billingDetailsService
      .getBillingDetails()
      .subscribe((billingDetailsResp) => {
        if (billingDetailsResp.length > 0) {
          this.billingDetails = billingDetailsResp.filter(
            (billingDetail) =>
              billingDetail.user != null &&
              billingDetail.user.email === this.user.email
          )[0];
          this.setBillingData();
        }
      });
  }

  getUser() {
    this._usersv
      .getUserPk(this.contract.user_customer.pk)
      .subscribe((userResp) => {
        this.user = userResp;
        if (!this.user.customer.address) {
          this.user.customer.address = new Address();
        }
        if (!this.user.nationalid) {
          this.user.nationalid = new NationalId();
        }

        this.getBillingDetails();
        this.hasCreditCard();
      });
  }

  setBillingData() {
    if (this.billingDetails && this.billingDetails.physical_person) {
      this.user.customer.address = new Address();
      this.user.customer.address = this.billingDetails.physical_person.address;
      this.user.first_name = this.billingDetails.physical_person.first_name;
      this.user.last_name = this.billingDetails.physical_person.last_name;
      this.user.second_last_name =
        this.billingDetails.physical_person.second_last_name;
      this.user.nationalid = this.billingDetails.physical_person.national_id;
    }
  }

  getTransferPayment() {
    this.paymentServices
      .getTransferPayment(this.idContract)
      .subscribe((response) => {
        this.transferPayments = response.payment_list;
        this.transferPayments.forEach((transferPayment) => {
          transferPayment.sameMonthDate = paymentDateSameMonth(
            transferPayment.payment_date
          );
          transferPayment.laterThanDeadLine = paymentDateTwoDaysBeforeEnd(
            transferPayment.payment_date
          );

          if (this.isInvoiceModifiable(transferPayment)) {
            transferPayment.modifiable = true;
          }
        });

        this.budget = response.contract_price;
        this.transferPayment.contract_price = this.budget;
        if (response.payment_list != undefined) {
          this.totalPayed = response.payment_list
            .reduce((sum, current) => Big(current.amount).add(sum), Big(0))
            .toFixed(2);
        }
      });
  }

  getCompanyConstitution() {
    if (this.contract.juridical_person) {
      this._constitutionsv
        .getCompanyConstitution(this.contract.juridical_person)
        .subscribe((companyResp) => {
          if (companyResp) {
            this.company = companyResp;

            if (!this.company.registered_office) {
              this.company.registered_office = new Address();
            }
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.idContractInput != undefined) {
      this.idContract = this.idContractInput;
    }
    if (this.idContract != undefined) {
      this.paymentServices
        .getTransferPayment(this.idContract)
        .subscribe((response) => {
          this.paymentServices
            .getCurrentStep(this.idContract)
            .subscribe((responseStep) => {
              this.transferPayments = response.payment_list;
              this.budget = response.contract_price;
              this.transferPayment.step = responseStep.step + 1;
              if (
                this.transferPayments != undefined &&
                this.transferPayments.length > 0
              ) {
                this.totalPayed = response.payment_list
                  .reduce(
                    (sum, current) => Big(current.amount).add(sum),
                    Big(0)
                  )
                  .toFixed(2);
              }
              this.transferPayments.forEach((transferPayment) => {
                if (this.isInvoiceModifiable(transferPayment)) {
                  transferPayment.modifiable = true;
                }
                transferPayment.sameMonthDate = paymentDateSameMonth(
                  transferPayment.payment_date
                );
                transferPayment.laterThanDeadLine = paymentDateTwoDaysBeforeEnd(
                  transferPayment.payment_date
                );
              });
            });
        });
    }
    if (this.idContractInput != undefined) {
      this.idContract = this.idContractInput;
    }
  }

  private isInvoiceModifiable(trasferPayment: TransferPayment): Boolean {
    if (
      trasferPayment.juridical_person &&
      trasferPayment.juridical_person.CIF
    ) {
      return false;
    } else if (
      trasferPayment.physical_person &&
      trasferPayment.physical_person.national_id &&
      trasferPayment.physical_person.national_id.national_id &&
      trasferPayment.physical_person.address
    ) {
      return false;
    } else {
      return true;
    }
  }

  submit() {
    this.loading = true;
    if (this.form.valid) {
      if (this.transferPayment.outPayment) {
        if (this.paymentType != 'Targeta') {
          if (this.is_invoice_return == true) {
            this.transferPayment.amount = -1 * this.transferPayment.amount;
          }
          if (this.transferPayment.contract_price == '') {
            this.transferPayment.contract_price = null;
          }
          this.transferPayment.type = this.paymentType;
          this.transferPayment.disbursements = this.alreadyAddedDisbursements;
          this.transferPayment = this.transformUserAndCompanyToInvoice(
            this.transferPayment
          );
          if (Number(this.productId) == 7) {
            this.transferPayment.DONT_GENERATE_INVOICE = true;
          }
          if (this.method != undefined && this.callMethod) {
            this.transferPayment.enable_notifications = false;
          } else {
            this.transferPayment.enable_notifications = true;
          }
          this.paymentServices
            .transferPayment(this.idContract, this.transferPayment)
            .pipe(
              switchMap((response) => {
                if (this.method != undefined && this.callMethod) {
                  this.method();
                }
                this.transferPayment = new TransferPayment();
                if (this.method == undefined) {
                  this.transferPayment.outPayment = true;
                }
                return of(null); // Return an observable that completes immediately
              }),
              catchError((error) => {
                this.loading = false;
                this.transferPayment = new TransferPayment();
                if (this.method == undefined) {
                  this.transferPayment.outPayment = true;
                }
                this.djangoError.handleError(error, this.form);
                return throwError(error);
              })
            )
            .subscribe(() => {
              this.loading = false;
              this.closePopup();
            });
        } else {
          let params = {
            customer_id: this.contract.customer,
            amount: this.transferPayment.amount,
            card: this.paymentCard.id,
            contract_id: this.contract.id,
            concept: this.transferPayment.concept,
            payment_date: this.transferPayment.payment_date,
            step: this.transferPayment.step,
            society_invoice: this.transferPayment.society_invoice,
            contract_price: this.transferPayment.contract_price,
            disbursements: this.alreadyAddedDisbursements,
          };
          this.paymentServices.authorizeSinglePayment(params).subscribe(
            (response) => {
              if (response.result.result === '00') {
                this.textPayment = response.result.message;
                this.showGenericPopUp = true;
              } else {
                this.textPayment = response.result.message;
                this.showGenericPopUp = true;
                throwError(new Error(response.result.message));
              }
            },
            (error) => {
              if (error.error && error.error.card) {
                this.textPayment = error.card;
                throwError(new Error(error.card));
              } else {
                this.textPayment = error;
                throwError(new Error(error));
              }
              this.showGenericPopUp = true;
            }
          );
          this.closePopup();
        }
      } else {
        this.loading = false;
        if (this.method != undefined && this.callMethod) {
          this.method();
        }
        this.transferPayment = new TransferPayment();
        if (this.method == undefined) {
          this.transferPayment.outPayment = true;
        }
        this.closePopup();
      }
    }
  }

  private transformUserAndCompanyToInvoice(invoice) {
    if (this.disbursements.length > 0) {
      this.transferPayment.disbursements = this.disbursements;
    }
    if (this.transferPayment.society_invoice) {
      invoice.juridical_person = this.company;
      invoice.juridical_person.logo = undefined;
      invoice.society_invoice = true;
    } else {
      invoice.physical_person = new PhysicalPerson();
      invoice.physical_person.first_name = this.user.first_name;
      invoice.physical_person.last_name = this.user.last_name;
      invoice.physical_person.second_last_name = this.user.second_last_name;
      console.log(this.user.last_name);
      console.log(this.user.second_last_name);
      if (
        this.user.nationalid &&
        !Object.values(this.user.nationalid).every((x) => x == undefined)
      ) {
        invoice.physical_person.national_id = this.user.nationalid;
      }
      if (
        this.user.customer.address &&
        !Object.values(this.user.customer.address).every((x) => x == undefined)
      ) {
        invoice.physical_person.address = this.user.customer.address;
      }
    }

    return invoice;
  }

  closePopup() {
    this.loading = false;
    this.showPopup = false;
    this.showPopupResume = false;
    this.showPopUpUser = false;
    this.paymentType = null;
    this.paymentCard = null;
    this.is_invoice_return = false;
    this.showPopupChange.emit(this.showPopup);
    this.showPopupResumeChange.emit(this.showPopupResume);
    this.showPopUpUserChange.emit(this.showPopUpUser);
  }

  updateBudget() {
    var e = <HTMLInputElement>document.getElementById('budget');
    this.budget = e.value;
    let contract = new Contrato();
    contract.contract_price = Number(this.budget);
    contract.product_id = Number(this.productId);
    contract.id = Number(this.idContract);
    this.contratoService.patchContrato(this.idContract, contract).subscribe();
    this.changeBudget = !this.changeBudget;
  }

  updateAmount(index) {
    let patchTransfer = new TransferPayment();
    patchTransfer.id = this.transferPayments[index].id;
    patchTransfer.amount = this.transferPayments[index].amount;
    patchTransfer.society_invoice =
      this.transferPayments[index].society_invoice;
    patchTransfer.payment_date = undefined;

    this.paymentServices
      .patchTransferPayment(this.idContract, patchTransfer)
      .subscribe((response) => {
        this.transferPayments[index] = response;
      });
    this.totalPayed = this.transferPayments
      .reduce((sum, current) => Big(current.amount).add(sum), Big(0))
      .toFixed(2);
  }

  invoiceDownload(transfer) {
    this._trackingsv.downloadInvoiceFile(transfer);
  }

  showPopUpUserGenerate(transfer) {
    this.showPopUpUser = true;
    this.showPopupResume = false;
    this.showPopupResumeChange.emit(this.showPopupResume);
    this.showPopUpUserChange.emit(this.showPopUpUser);
    this.payment_id = transfer.id;
    this.transferPayment = transfer;
    this.transferPayment.outPayment = true;
  }

  submitInvoice() {
    this.transferPayment.payment_date = undefined;
    if (
      this.transferPayment.juridical_person &&
      this.transferPayment.juridical_person.logo
    ) {
      this.transferPayment.juridical_person.logo = undefined;
    }
    this.paymentServices
      .patchTransferPayment(this.idContract, this.transferPayment)
      .subscribe((transferPayment) => {
        let invoice = new Invoice();
        invoice.society_invoice = this.transferPayment.society_invoice;
        invoice = this.transformUserAndCompanyToInvoice(invoice);
        invoice.pk = Number(this.transferPayment.invoice_id);
        if (invoice.pk) {
          this._trackingsv
            .putInvoice(this.payment_id, invoice)
            .subscribe((resp) => {
              this.loading = false;
              this.showPopUpUser = false;
              this.showPopUpUserChange.emit(this.showPopUpUser);
              this.showPopupResume = true;
              this.showPopupResumeChange.emit(this.showPopupResume);
            });
        } else {
          this._trackingsv
            .postInvoice(this.payment_id, invoice)
            .subscribe((resp) => {
              this.loading = false;
              this.showPopUpUser = false;
              this.showPopUpUserChange.emit(this.showPopUpUser);
              this.showPopupResume = true;
              this.showPopupResumeChange.emit(this.showPopupResume);
            });
        }
      });
  }

  isInvoiceReturn() {
    this.is_invoice_return = !this.is_invoice_return;
  }

  hasCreditCard() {
    if (this.user.customer) {
      this.paymentServices
        .getCustomerCards(this.user.customer.pk)
        .subscribe((resp) => {
          if (resp.length > 0) {
            this.creditCards = resp;
          }
        });
    }
  }

  canAddDisbursement(): boolean {
    if (this.alreadyAddedDisbursements.length === 0) {
      return true;
    } else {
      const lastDisbursement =
        this.alreadyAddedDisbursements[
          this.alreadyAddedDisbursements.length - 1
        ];
      return (
        lastDisbursement && lastDisbursement.concept && lastDisbursement.cost
      );
    }
  }

  areAllDisbursementsFilled(): boolean {
    return this.alreadyAddedDisbursements.every(
      (disbursement) => disbursement.concept && disbursement.cost
    );
  }

  addDisbursement() {
    if (this.canAddDisbursement()) {
      this.alreadyAddedDisbursements.push({});
    }
  }

  deleteCost(index: number) {
    this.alreadyAddedDisbursements.splice(index, 1);
  }
}
