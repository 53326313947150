import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {
  Faqs,
  Product,
  ProductDescription,
  ProductPrice,
  StepHome,
} from '../models/products';
import {FieldType} from '../models/tracking';
import {globalApi} from './global';

@Injectable({
  providedIn: 'root',
})
export class ProductsServices {
  public url: string;

  constructor(private http: HttpClient) {
    this.url = globalApi.url;
  }

  getAllProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(this.url + 'product/?all=true');
  }

  getAvailableProducts(filter?: string): Observable<Product[]> {
    if (filter) {
      return this.http.get<Product[]>(
        this.url + 'product/?assistance=' + filter
      );
    } else {
      return this.http.get<Product[]>(this.url + 'product/');
    }
  }

  getProduct(idProduct: Number): Observable<Product> {
    return this.http.get<Product>(this.url + 'product/' + idProduct + '/');
  }

  getProductByURL(url: string): Observable<any> {
    return this.http.get<any>(this.url + 'product/?url=' + url).pipe(
      map((response) => {
        if (response.length > 0) {
          let product: Product = response[0];

          var url = new URL(product.home_image);
          product.home_image = product.home_image.replace(
            url.hostname,
            environment.cdn
          );
          product.home_image_mobile = product.home_image_mobile.replace(
            url.hostname,
            environment.cdn
          );
          return product;
        } else {
          return throwError('error');
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getFaqs(idProduct: Number): Observable<Faqs[]> {
    return this.http
      .get<Faqs[]>(this.url + 'product/' + idProduct + '/faqs')
      .pipe(
        map((response) => {
          return response.sort((x, y) => (x.order > y.order ? 1 : -1));
        })
      );
  }

  putFaqs(idProduct: Number, faqs: Faqs): Observable<Faqs> {
    return this.http.put<Faqs>(
      this.url + 'product/' + idProduct + '/faqs/' + faqs.id,
      faqs
    );
  }

  postFaqs(idProduct: Number, faqs: Faqs): Observable<Faqs> {
    return this.http.post<Faqs>(
      this.url + 'product/' + idProduct + '/faqs',
      faqs
    );
  }

  deleteFaqs(idProduct: Number, faqs: Faqs): Observable<Faqs> {
    return this.http.delete<Faqs>(
      this.url + 'product/' + idProduct + '/faqs/' + faqs.id
    );
  }

  getSteps(idProduct: Number): Observable<any[]> {
    return this.http
      .get<any[]>(this.url + 'product/' + idProduct + '/steps')
      .pipe(
        map((response) => {
          response.forEach((item) => {
            var url = new URL(item.image);
            item.image = item.image.replace(url.hostname, environment.cdn);
          });
          response = response.sort((x, y) => (x.order > y.order ? 1 : -1));
          return response;
        })
      );
  }

  putSteps(
    idProduct: Number,
    step: StepHome,
    fileToUpload: File
  ): Observable<Object> {
    const endpoint = this.url + 'product/' + idProduct + '/steps/' + step.id;
    var formData: FormData = new FormData();
    console.log(fileToUpload);
    if (fileToUpload) {
      formData.append('image', fileToUpload, fileToUpload.name);
    }
    formData = this.createFormData(step, formData);
    return this.http.put<Object>(endpoint, formData);
  }

  postSteps(
    idProduct: Number,
    step: StepHome,
    fileToUpload: File
  ): Observable<Object> {
    const endpoint = this.url + 'product/' + idProduct + '/steps';
    var formData: FormData = new FormData();
    if (fileToUpload) {
      formData.append('image', fileToUpload, fileToUpload.name);
    }
    formData = this.createFormData(step, formData);
    return this.http.post<Object>(endpoint, formData);
  }

  deleteSteps(idProduct: Number, step: StepHome): Observable<Faqs> {
    return this.http.delete<Faqs>(
      this.url + 'product/' + idProduct + '/steps/' + step.id
    );
  }

  postProduct(product: Product): Observable<Product> {
    return this.http.post<Product>(this.url + 'product/', product);
  }

  putProduct(product: Product): Observable<Product> {
    return this.http.put<Product>(
      this.url + 'product/' + product.id + '/',
      product
    );
  }

  patchProductFile(
    product: Product,
    fileToUpload?: File,
    mobileFileToUpload?: File
  ): Observable<Product> {
    const endpoint = this.url + 'product/' + product.id + '/';
    var formData: FormData = new FormData();
    if (fileToUpload) {
      formData.append('home_image', fileToUpload, fileToUpload.name);
    }

    if (mobileFileToUpload) {
      formData.append(
        'home_image_mobile',
        mobileFileToUpload,
        mobileFileToUpload.name
      );
    }

    product.product_description_image = undefined;

    formData = this.createFormData(product, formData);

    return this.http.patch<Product>(endpoint, formData);
  }

  private createFormData(object, formData) {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        if (object[key] instanceof Object && !(object[key] instanceof Date)) {
          for (let nestedKey in object[key]) {
            if (object[key][nestedKey] != undefined) {
              formData.append(key + '.' + nestedKey, object[key][nestedKey]);
            }
          }
        } else if (object[key] instanceof Date) {
          formData.append(key, object[key].toISOString());
        } else {
          if (
            object[key] != undefined &&
            key != 'home_image' &&
            key != 'home_image_mobile' &&
            key != 'image' &&
            key != 'html_post_payment_image'
          ) {
            formData.append(key, object[key]);
          }
        }
      }
    }
    return formData;
  }

  getProductPrices(product_id): Observable<ProductPrice[]> {
    return this.http.get<ProductPrice[]>(
      this.url + 'product/product_prices?product_id=' + product_id
    );
  }

  getProductPricesSimple(product_id: number): Observable<ProductPriceSimple[]> {
    return this.http.get<ProductPriceSimple[]>(
      `${this.url}product/${product_id}/product_prices_simple`
    );
  }

  putProductPrice(
    product_price: ProductPrice,
    fileToUpload?: File
  ): Observable<ProductPrice> {
    const endpoint =
      this.url + 'product/product_prices/' + product_price.id + '/';
    var formData: FormData = new FormData();
    let headers = new HttpHeaders().set('Accept-Language', 'en');
    if (fileToUpload) {
      formData.append(
        'html_post_payment_image',
        fileToUpload,
        fileToUpload.name
      );
    }

    formData = this.createFormData(product_price, formData);

    return this.http.patch<ProductPrice>(endpoint, formData, {
      headers: headers,
    });
  }

  postProductPrice(
    product_price: ProductPrice,
    fileToUpload?: File
  ): Observable<ProductPrice> {
    const endpoint = this.url + 'product/product_prices/';
    var formData: FormData = new FormData();
    let headers = new HttpHeaders().set('Accept-Language', 'en');
    if (fileToUpload) {
      formData.append(
        'html_post_payment_image',
        fileToUpload,
        fileToUpload.name
      );
    }

    formData = this.createFormData(product_price, formData);

    return this.http.post<ProductPrice>(endpoint, formData, {
      headers: headers,
    });
  }

  getContractFields(product_id): Observable<FieldType[]> {
    return this.http.get<FieldType[]>(
      this.url + 'product/' + product_id + '/contract_field'
    );
  }

  deleteContractField(product_id, contract_field: FieldType): Observable<any> {
    return this.http.delete<any>(
      this.url +
        'product/' +
        product_id +
        '/contract_field/' +
        contract_field.id
    );
  }

  putContractField(
    product_id,
    contract_field: FieldType
  ): Observable<FieldType> {
    return this.http.put<FieldType>(
      this.url +
        'product/' +
        product_id +
        '/contract_field/' +
        contract_field.id,
      contract_field
    );
  }

  postContractField(
    product_id,
    contract_field: FieldType
  ): Observable<FieldType> {
    return this.http.post<FieldType>(
      this.url + 'product/' + product_id + '/contract_field',
      contract_field
    );
  }
}

export interface ProductPriceSimple {
  tier: string;
  total_price: number;
}
