<div
  *ngIf="showPopup || showPopupResume || showPopUpUser"
  class="loading"
  [ngStyle]="{ 'z-index': loading ? 2002 : 1500 }"
></div>
<div *ngIf="showPopup" class="popup">
  <div class="close-popup" aria-label="Close" (click)="closePopup()">
    <img src="assets/img/closes-popups.svg" alt="" />
  </div>
  <div class="title">Añadir pago</div>
  <br />
  <form #form="ngForm" (ngSubmit)="submit()">
    <div *ngIf="method != undefined" class="row mt-3">
      <div class="col">
        <select
          name="outPayment"
          #outPayment="ngModel"
          [(ngModel)]="transferPayment.outPayment"
          required=""
          class="form-control"
        >
          <option value="undefined" disabled>
            ¿Quieres añadir un pago en la plataforma?
          </option>
          <option [ngValue]="true">SÍ</option>
          <option [ngValue]="false">NO</option>
        </select>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; margin-top: 10px">
      <div>
        <label style="padding-left: 0">¿Es una devolución?</label>
      </div>
      <div class="margintoggleButton">
        <label class="switch">
          <input
            type="checkbox"
            id="togBtn"
            (click)="isInvoiceReturn(obj)"
            [checked]="aceptedcc === true"
          />
          <div
            class="slider3 round"
            [ngClass]="aceptedcc === true ? 'slider2' : 'slider'"
          >
            <span class="on">Sí</span><span class="off">No</span>
          </div>
        </label>
      </div>
    </div>
    <ng-container *ngIf="transferPayment.outPayment">
      <div class="row mt-3">
        <div class="col">
          <div class="form-floating">
            <!-- <p id="payment-type-text" for="payment-type">Selecciona una opción de pago</p> -->
            <select
              id="payment-type"
              name="payment_type"
              class="form-control inputsCssLab"
              placeholder="Tipo de pago"
              required
              #paymentTypet="ngModel"
              [(ngModel)]="paymentType"
              required
            >
              <option disabled selected value="null">
                -- Introduce método de pago --
              </option>
              <option *ngIf="creditCards && creditCards.length > 0">
                Targeta
              </option>
              <option>Transferencia</option>
              <option>Efectivo</option>
              <option>Bizum</option>
            </select>
            <small
              *ngIf="!paymentTypet.valid && paymentTypet.errors != null"
              style="color: #fecc00"
            >
              {{ paymentTypet.errors.message }}
            </small>
            <small *ngIf="paymentType == 'Bizum'" style="color: #fecc00">
              Recuerda que la forma recomendada de realizar un bizum es
              utilizando el link de pago
            </small>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="form-floating" *ngIf="paymentType === 'Targeta'">
            <select
              id="payment-type"
              name="payment_type"
              class="form-control inputsCssLab"
              placeholder="Targeta"
              #paymentCardt="ngModel"
              required
              [(ngModel)]="paymentCard"
            >
              <option disabled selected value="null">
                -- Escoja una targeta --
              </option>
              <option *ngFor="let card of creditCards" [ngValue]="card">
                **** **** **** {{ card.last_4_digits || "****" }} -
                {{ (card.expiration_date | date : "MM/YY") || "" }}
              </option>
            </select>
            <small
              *ngIf="!paymentCardt.valid && paymentCardt.errors != null"
              style="color: #fecc00"
            >
              {{ paymentCardt.errors.message }}
            </small>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="form-floating">
            <input
              type="text"
              name="concept"
              #concept="ngModel"
              [(ngModel)]="transferPayment.concept"
              required
              placeholder="Concepto del pago"
              class="form-control inputsCssLab"
            />
            <label class="floating-label" for="concept"
              >Concepto del pago</label
            >
            <small
              *ngIf="!concept.valid && concept.errors != null"
              style="color: #fecc00"
            >
              {{ concept.errors.message }}
            </small>
          </div>
        </div>
      </div>
      <div class="row mt-3" *ngIf="transferPayment.step === 1">
        <div class="col">
          <div class="form-floating">
            <input
              type="number"
              appDisableMousewheelScroll
              name="contract_price"
              #contract_price="ngModel"
              [(ngModel)]="transferPayment.contract_price"
              placeholder="¿Precio total del servicio con IVA​?"
              class="form-control inputsCssLab"
            />
            <label class="floating-label" for="contract_price"
              >¿Precio total del servicio con IVA​?</label
            >
            <small
              *ngIf="!contract_price.valid && contract_price.errors != null"
              style="color: #fecc00"
            >
              {{ contract_price.errors.message }}
            </small>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="form-floating">
            <input
              type="number"
              appDisableMousewheelScroll
              name="amount"
              #amount="ngModel"
              [(ngModel)]="transferPayment.amount"
              required
              placeholder="¿Qué cantidad se ha pagado?"
              class="form-control inputsCssLab"
            />
            <label class="floating-label" for="amount"
              >¿Qué cantidad se ha pagado?</label
            >
            <small
              *ngIf="!amount.valid && amount.errors != null"
              style="color: #fecc00"
            >
              {{ amount.errors.message }}
            </small>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="form-floating">
            <input
              type="date"
              name="payment_date"
              #payment_date="ngModel"
              [(ngModel)]="transferPayment.payment_date"
              required
              placeholder="¿Cuando se hizo el pago?"
              class="form-control floatingDate"
            />
            <label class="floating-label" for="payment_date"
              >¿Cuando se hizo el pago?</label
            >

            <small
              *ngIf="!payment_date.valid && payment_date.errors != null"
              style="color: #fecc00"
            >
              {{ payment_date.errors.message }}
            </small>
          </div>
        </div>
      </div>

      <!-- AÑADIR SOCIEDAD OPTIONS -->
    </ng-container>

    <ng-container
      *ngIf="!transferPayment.society_invoice"
      [ngTemplateOutlet]="invoiceForm"
      [ngTemplateOutletContext]="{
        user: user,
        transferPayment: transferPayment,
        form: form
      }"
    >
    </ng-container>
    <ng-container
      *ngIf="transferPayment.society_invoice"
      [ngTemplateOutlet]="societyInvoiceForm"
      [ngTemplateOutletContext]="{
        company: company,
        transferPayment: transferPayment,
        form: form
      }"
    >
    </ng-container>
    <div
      *ngIf="alreadyAddedDisbursements.length > 0"
      class="disbursement-title"
    >
      <b>Estos son los suplidos que ya están añadidos actualmente:</b>
    </div>
    <div class="cost-disbursement">
      <div class="grid-costs">
        <b>Concepto</b>
        <b class="item-amount">Coste</b>
        <b></b>
      </div>
    </div>
    <div
      *ngFor="let cost of alreadyAddedDisbursements; let i = index"
      class="cost-disbursement"
    >
      <div class="row">
        <div class="grid-costs">
          <div class="col">
            <input
              type="text"
              name="costConcept{{ i }}"
              [(ngModel)]="cost.concept"
              placeholder="Concepto"
            />
          </div>
          <div class="col">
            <input
              type="text"
              name="costCost{{ i }}"
              [(ngModel)]="cost.cost"
              placeholder="Coste"
            />
          </div>
          <button type="button" (click)="deleteCost(i)">
            <img
              src="assets/img/delete-white.png"
              width="22px"
              height="30px"
              alt="Eliminar Suplido"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <button
          [disabled]="!canAddDisbursement()"
          (click)="addDisbursement()"
          class="btn-fr"
          type="button"
        >
          Agregar suplido
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <input type="submit" value="Guardar" class="btn-fr" />
      </div>
    </div>
  </form>
</div>
<div *ngIf="showPopupResume" class="popup">
  <div class="close-popup" aria-label="Close" (click)="closePopup()">
    <img src="assets/img/closes-popups.svg" alt="" />
  </div>
  <div class="title">Pagos por fuera</div>
  <br />
  <table>
    <thead>
      <tr>
        <th>Fecha</th>
        <th>Concepto</th>

        <th>Tipo pago</th>
        <th style="width: 82px">Cantidad</th>
        <th></th>
        <th>Factura</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tr *ngFor="let transfer of transferPayments; index as i">
      <td>
        {{ transfer.payment_date | date : "yyyy-MM-dd" }}
      </td>
      <td>
        {{ transfer.concept }}
      </td>

      <td>
        {{ transfer.type }}
      </td>
      <td style="width: 82px">
        <ng-container *ngIf="!transfer.change_amount">
          <b>{{ transfer.amount }} €</b>
        </ng-container>
        <ng-container *ngIf="transfer.change_amount">
          <input
            style="
              float: left;
              width: 60px;
              height: 25px !important;
              padding: 0px 5px !important;
            "
            type="number"
            appDisableMousewheelScroll
            name="amount"
            [(ngModel)]="transfer.amount"
          />
        </ng-container>
      </td>
      <td *ngIf="is_staff">
        <ng-container *ngIf="!transfer.change_amount">
          <div
            (click)="transfer.change_amount = !transfer.change_amount"
            style="cursor: pointer"
          >
            <span class="material-icons-outlined edit-icon"> edit_note </span>
          </div>
        </ng-container>
        <ng-container *ngIf="transfer.change_amount">
          <div
            (click)="updateAmount(i)"
            style="float: left; margin-left: 5px; cursor: pointer"
          >
            <span class="material-icons-sharp save-icon"> save </span>
          </div>
        </ng-container>
      </td>
      <td
        (click)="invoiceDownload(transfer)"
        *ngIf="transfer.invoice_file_name"
        style="cursor: pointer"
      >
        Descargar
      </td>
      <td
        (click)="showPopUpUserGenerate(transfer)"
        *ngIf="
          !transfer.invoice_file_name ||
          transfer.sameMonthDate ||
          transfer.modifiable
        "
        style="cursor: pointer"
      >
        Generar
      </td>
    </tr>
    <tr>
      <td>TOTAL pagado</td>
      <td></td>
      <td></td>
      <td>{{ totalPayed }} €</td>
    </tr>
    <tr style="border-top: 2px solid #fff !important">
      <td>
        <b>Precio TOTAL</b>
      </td>
      <td></td>
      <td></td>
      <td>
        <ng-container *ngIf="!changeBudget">
          <b>{{ budget }}€</b>
        </ng-container>
        <ng-container *ngIf="changeBudget">
          <input
            style="
              float: left;
              width: 60px;
              height: 25px !important;
              padding: 0px 5px !important;
            "
            type="number"
            appDisableMousewheelScroll
            name="budget"
            id="budget"
            value="{{ budget }}"
          />
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="!changeBudget && is_staff">
          <a (click)="changeBudget = !changeBudget" style="cursor: pointer">
            <span class="material-icons-outlined edit-icon"> edit_note </span>
          </a>
        </ng-container>
        <ng-container *ngIf="changeBudget">
          <a
            (click)="updateBudget()"
            style="float: left; margin-left: 5px; cursor: pointer"
          >
            <span class="material-icons-sharp save-icon"> save </span>
          </a>
        </ng-container>
      </td>
    </tr>
  </table>
</div>

<div
  *ngIf="
    (showPopUpUser && user && !transferPayment.laterThanDeadLine) ||
    (showPopUpUser && user && transferPayment.laterThanDeadLine && is_staff) ||
    (showPopUpUser && company && !transferPayment.laterThanDeadLine) ||
    (showPopUpUser && company && transferPayment.laterThanDeadLine && is_staff)
  "
  class="popup"
>
  <div class="close-popup pepito" aria-label="Close" (click)="closePopup()">
    <img src="assets/img/closes-popups.svg" alt="" />
  </div>
  <form #userForm="ngForm" (ngSubmit)="submitInvoice()">
    <div class="row mt-3">
      <div class="col">
        <div class="form-floating">
          <input
            type="text"
            name="concept"
            #concept="ngModel"
            [(ngModel)]="transferPayment.concept"
            required
            placeholder="Concepto del pago"
            class="form-control inputsCssLab"
          />
          <label class="floating-label" for="concept">Concepto del pago</label>
          <small
            *ngIf="!concept.valid && concept.errors != null"
            style="color: #fecc00"
          >
            {{ concept.errors.message }}
          </small>
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="!transferPayment.society_invoice"
      [ngTemplateOutlet]="invoiceForm"
      [ngTemplateOutletContext]="{
        user: user,
        transferPayment: transferPayment,
        form: userForm
      }"
    >
    </ng-container>
    <ng-container
      *ngIf="transferPayment.society_invoice"
      [ngTemplateOutlet]="societyInvoiceForm"
      [ngTemplateOutletContext]="{
        company: company,
        transferPayment: transferPayment,
        form: userForm
      }"
    >
    </ng-container>
    <div class="row mt-3">
      <div class="col">
        <input type="submit" value="Generar" class="btn-fr" />
      </div>
    </div>
  </form>
</div>

<div
  *ngIf="
    (showPopUpUser && user && transferPayment.laterThanDeadLine && !is_staff) ||
    (showPopUpUser && company && transferPayment.laterThanDeadLine && !is_staff)
  "
  class="popup"
  style="overflow: hidden; max-width: 590px; top: 200px"
>
  <div class="close-popup pepito" aria-label="Close" (click)="closePopup()">
    <img src="assets/img/closes-popups.svg" alt="" />
  </div>
  <p class="title" style="font-size: 18px">
    Para modificar los datos de facturación póngase en contacto enviando un
    correo a la siguiente dirección email:
    <a href="mailto:julia@enley.com" target="_blank" class="email"
      >julia&#64;enley.com</a
    >
  </p>
</div>

<ng-template
  #invoiceForm
  let-user="user"
  let-transferPayment="transferPayment"
  let-form="form"
>
  <form #formTemplate="ngForm">
    <fieldset ngModelGroup="user" *ngIf="transferPayment.outPayment">
      <ng-container
        [ngTemplateOutlet]="invoiceType"
        [ngTemplateOutletContext]="{ transferPayment: transferPayment }"
      >
      </ng-container>
      <label
        style="
          padding-top: 15px !important;
          padding-bottom: 0px !important;
          color: white !important;
        "
        class="formConvenioLabels convenioReguladorInputsSize"
        >Datos facturación</label
      >
      <div class="row">
        <div class="col mt-3">
          <div class="form-floating">
            <input
              type="text"
              class="form-control inputsCssLab invoice-input"
              name="first_name"
              #first_name="ngModel"
              [(ngModel)]="user.first_name"
              required
              placeholder="Nombre"
              required
              class="form-control inputsCssLab"
            />
            <label class="floating-label" for="payment_date">Nombre</label>

            <small
              *ngIf="!first_name.valid && form.submitted"
              style="color: red"
            >
              Completa este campo
            </small>
            <small
              *ngIf="!first_name.valid && first_name.errors != null"
              style="color: red"
            >
              {{ first_name.errors.message }}
            </small>
          </div>
        </div>
        <div class="col mt-3">
          <div class="form-floating">
            <input
              type="text"
              class="form-control inputsCssLab invoice-input"
              name="last_name"
              #last_name="ngModel"
              required
              [(ngModel)]="user.last_name"
              placeholder="Primer apellido"
              class="form-control inputsCssLab"
            />
            <label class="floating-label" for="payment_date"
              >Primer apellido</label
            >

            <small
              *ngIf="!last_name.valid && form.submitted"
              style="color: red"
            >
              Completa este campo
            </small>
            <small
              *ngIf="!last_name.valid && last_name.errors != null"
              style="color: red"
            >
              {{ last_name.errors.message }}
            </small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mt-3">
          <div class="form-floating">
            <input
              type="text"
              class="form-control inputsCssLab invoice-input"
              name="second_last_name"
              #second_last_name="ngModel"
              [(ngModel)]="user.second_last_name"
              placeholder="Segundo apellido"
              class="form-control inputsCssLab"
            />
            <label class="floating-label" for="payment_date"
              >Segundo apellido</label
            >

            <small
              *ngIf="!second_last_name.valid && form.submitted"
              style="color: red"
            >
              Completa este campo
            </small>
            <small
              *ngIf="!second_last_name.valid && second_last_name.errors != null"
              style="color: red"
            >
              {{ second_last_name.errors.message }}
            </small>
          </div>
        </div>
        <div
          *ngIf="
            transferPayment.amount >= 400 ||
            transferPayment.amount <= -400 ||
            transferPayment.modifiable
          "
          class="col mt-3"
        >
          <div class="form-floating">
            <input
              type="text"
              class="form-control inputsCssLab invoice-input"
              required
              name="national_id"
              #national_id="ngModel"
              [(ngModel)]="user.nationalid.national_id"
              placeholder="DNI/NIE"
              class="form-control inputsCssLab"
              appValidDNI=""
            />
            <label class="floating-label" for="payment_date">DNI/NIE</label>

            <small
              *ngIf="!national_id.valid && form.submitted"
              style="color: red"
            >
              Completa este campo
            </small>
            <small
              *ngIf="national_id.errors && national_id.errors.dni"
              style="color: red"
            >
              El DNI/NIE no es válido.
            </small>
            <small
              *ngIf="!national_id.valid && national_id.errors != null"
              style="color: red"
            >
              {{ national_id.errors.message }}
            </small>
          </div>
        </div>
      </div>
      <fieldset
        ngModelGroup="customer"
        *ngIf="
          transferPayment.amount >= 400 ||
          transferPayment.amount <= -400 ||
          transferPayment.modifiable
        "
      >
        <div class="añadir-pago-address">
          <app-address
            [address]="user.customer.address"
            [control]="form"
            [nacionalidad]="false"
            [customer]="user.customer"
            [invoice]="true"
            [postal_code_required]="true"
            [changeDistance]="true"
          ></app-address>
        </div>
      </fieldset>
    </fieldset>
  </form>
</ng-template>

<ng-template
  #societyInvoiceForm
  let-company="company"
  let-transferPayment="transferPayment"
  let-generate="generate"
  let-form="form"
  let-invoice="invoice"
>
  <form #formTemplate="ngForm">
    <fieldset ngModelGroup="company" *ngIf="transferPayment.outPayment">
      <ng-container
        [ngTemplateOutlet]="invoiceType"
        [ngTemplateOutletContext]="{ transferPayment: transferPayment }"
      >
      </ng-container>
      <label
        style="
          padding-top: 15px !important;
          padding-bottom: 0px !important;
          color: white !important;
        "
        class="formConvenioLabels convenioReguladorInputsSize"
        >Datos facturación</label
      >
      <div class="row">
        <div class="col mt-3">
          <div class="form-floating">
            <input
              type="text"
              class="form-control inputsCssLab invoice-input"
              name="social_denomination_1"
              #social_denomination_1="ngModel"
              [(ngModel)]="company.social_denomination_1"
              required
              placeholder="Nombre"
              class="form-control inputsCssLab"
            />
            <label class="floating-label" for="payment_date">Nombre</label>

            <small
              *ngIf="!social_denomination_1.valid && form.submitted"
              style="color: red"
            >
              Completa este campo
            </small>
            <small
              *ngIf="
                !social_denomination_1.valid &&
                social_denomination_1.errors != null
              "
              style="color: red"
            >
              {{ social_denomination_1.errors.message }}
            </small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mt-3">
          <div class="form-floating">
            <input
              type="text"
              class="form-control inputsCssLab invoice-input"
              required
              name="CIF"
              #CIF="ngModel"
              [(ngModel)]="company.CIF"
              placeholder="CIF"
              class="form-control inputsCssLab"
            />
            <label class="floating-label" for="payment_date">CIF</label>
            <small
              *ngIf="!CIF.valid && CIF.errors.required && form.submitted"
              style="color: red"
            >
              Completa este campo
            </small>
            <small *ngIf="!CIF.valid && CIF.errors != null" style="color: red">
              {{ CIF.errors.message }}
            </small>
          </div>
        </div>
      </div>

      <fieldset
        ngModelGroup="customer"
        *ngIf="
          transferPayment.amount >= 400 ||
          transferPayment.amount <= -400 ||
          transferPayment.modifiable
        "
      >
        <div class="añadir-pago-address">
          <app-address
            [address]="company.registered_office"
            [label]="'Domicilio social'"
            [type]="'registered_office'"
            [control]="form"
            [surface]="true"
            [postal_code_required]="true"
            [idContract]="idContract"
            [invoice]="true"
            [surface]="false"
          >
          </app-address>
        </div>
      </fieldset>
    </fieldset>
  </form>
</ng-template>

<ng-template #invoiceType let-transferPayment="transferPayment">
  <div
    style="display: flex; flex-direction: column; white-space: nowrap"
    class=""
  >
    <label
      style="
        padding-top: 15px !important;
        padding-bottom: 0px !important;
        color: white !important;
      "
      class="formConvenioLabels convenioReguladorInputsSize"
      >Tipo de Factura</label
    >
    <div class="d-flex">
      <div
        class="form-check"
        style="padding-left: 0px; display: flex; margin-bottom: 10px"
      >
        <label class="address_checkbox-contain">
          <input
            [hidden]="true"
            class="form-check-input"
            id="choice"
            type="radio"
            name="society_invoice"
            #society_invoice="ngModel"
            [(ngModel)]="transferPayment.society_invoice"
            [value]="true"
          />
          <div class="address_checkbox-input"></div>
        </label>
        <div
          style="
            padding-right: 2px;
            font-weight: 300;
            color: #212529;
            margin-left: 10px;
          "
        >
          <span class="checkboxTextStyle">Sociedad</span>
        </div>
      </div>

      <div
        class="form-check"
        style="padding-left: 0px; display: flex; margin-bottom: 10px"
      >
        <label class="address_checkbox-contain">
          <input
            [hidden]="true"
            class="form-check-input"
            id="choice"
            type="radio"
            name="society_invoice"
            #society_invoice="ngModel"
            [(ngModel)]="transferPayment.society_invoice"
            required
            [value]="false"
          />
          <div class="address_checkbox-input"></div>
        </label>
        <div
          style="
            padding-right: 2px;
            font-weight: 300;
            color: #212529;
            margin-left: 10px;
          "
        >
          <span class="checkboxTextStyle">Persona física</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="showGenericPopUp">
  <div class="loading">
    <app-pop-up closable="true" (closePopUp)="showGenericPopUp = false">
      <p>{{ textPayment }}</p>
    </app-pop-up>
  </div>
</ng-container>
