import { ModuleWithProviders } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './apptemplate/error/error.component';
import { HomeComponent } from './apptemplate/home/home.component';
import { AdminGuard } from './admin.guard';
import { HomeOutletComponent } from './apptemplate/home-outlet/home-outlet.component';
import {
  BoxRegisterComponent,
  BoxRegisterResolver,
} from './user/full-register/box-register/box-register.component';
import { PagoconfirmacionComponent } from './contracts/pagoconfirmacion/pagoconfirmacion.component';
import { ContactoIframeComponent } from './user/contacto-iframe/contacto-iframe.component';
import { InitiateAuthenticationPaymentComponent } from './contracts/initiate-authentication-payment/initiate-authentication-payment.component';
import { GifComponent } from './gif/gif.component';
import { PagoerrorComponent } from './contracts/pagoerror/pagoerror.component';
import { PaymentRequestApiComponent } from './contracts/pre-payment/payment-request-api/payment-request-api.component';
import { ConstitucionesGraciasAltComponent } from './user/constituciones-gracias-alt/constituciones-gracias-alt.component';
import { PrivateAreaResolver } from './resolvers/private-area.resolver';
import { UnshortenURLComponent } from './services/payments/generate_url.service';

/**
 * Basic home routes.
 *
 * Exported to allow code to add to it (necessary for products)
 */
export const homeOutletRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      data: {
        titulo: 'Servicios Legales Online - Abogados Online',
        description:
          'Somos tu despacho de abogados online. Ofrecemos un servicio ágil, profesional y económico a solo un clic de ti ¡Visita nuestra web y consulta tus dudas!',
      },
    },
  },
  {
    path: '',
    loadChildren: () =>
      import('./static/static.module').then((m) => m.StaticModule),
  },
  {
    path: 'pago-confirmacion/:idpay/:idcontract/:step',
    component: PagoconfirmacionComponent,
  },
  {
    path: 'contrato',
    loadChildren: () =>
      import('./contracts/contracts.module').then((m) => m.ContractsModule),
  },
  {
    path: '',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./legal/legal.module').then((m) => m.LegalModule),
  },
  { path: 'error', component: ErrorComponent },
  {
    path: 'area-privada',
    component: ErrorComponent,
    resolve: { url: PrivateAreaResolver },
  },
  { path: '**', component: ErrorComponent },
];

/**
 * Root list of routes for the entire application
 */
export const appRoutes: Routes = [
  {
    path: 'box-register/:productid',
    component: BoxRegisterComponent,
    data: { data: { titulo: 'Box Register' } },
    resolve: { product: BoxRegisterResolver },
  },
  {
    path: 'c/:idContract/p/:idPaymentShort',
    component: UnshortenURLComponent,
  },
  {
    path: 'contrato/:idContract/payment/:idPayment/initiate-authentication',
    component: InitiateAuthenticationPaymentComponent,
  },
  {
    path: 'contrato/:idContract/payment/:idPayment/initiate-authentication/step/:step',
    component: InitiateAuthenticationPaymentComponent,
  },
  {
    path: 'contrato/:idcontract/pago-confirmacion',
    component: PagoconfirmacionComponent,
  },
  {
    path: 'contrato/:idcontract/pago-confirmacion/:idpay/pago',
    component: PagoconfirmacionComponent,
  },
  {
    path: 'contrato/:idcontract/pago-confirmacion/:idpay/:step',
    component: PagoconfirmacionComponent,
  },
  {
    path: 'contacto-iframe/:iframesize',
    component: ContactoIframeComponent,
    data: { data: { titulo: 'Contacto Iframe' } },
  },
  {
    path: 'gif',
    component: GifComponent,
    data: { data: { titulo: 'Cargando' } },
  },
  {
    path: 'contrato/:idContract/pago/:idPayment/step/:step/pago-error/:errorCode',
    component: PagoerrorComponent,
  },
  {
    path: 'contrato/:idContract/pago/:idPayment/pago-error/:errorCode',
    component: PagoerrorComponent,
  },
  {
    path: 'backoffice',
    loadChildren: () =>
      import('./backoffice/backoffice.module').then((m) => m.BackofficeModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'gracias-alt',
    component: ConstitucionesGraciasAltComponent,
    data: { data: { titulo: 'Gracias' } },
  },
  { path: 'mi-cuenta', redirectTo: 'area-privada' },
  {
    path: '',
    children: homeOutletRoutes,
    component: HomeOutletComponent,
  },
];

//Exportar el modulo de rutas
export const appRoutingProviders: any[] = []; // para establecer como servicio

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(
  appRoutes,
  {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
  }
);
