import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Card } from 'src/app/models/card';
import {
  Payment,
  Subscription,
  SubscriptionCardPayment,
} from 'src/app/models/payment';
import { globalApi } from '../global';
import { PaymentServices } from './payments.services';
import { InvoiceSubscriptions } from 'src/app/models/invoice';
import { assert } from 'console';

@Injectable({
  providedIn: 'root',
})
export class ScheduledServices {
  public url: string;
  constructor(
    private http: HttpClient,
    private router: Router,
    private paymentServices: PaymentServices
  ) {
    this.url = globalApi.url;
  }

  getSuscriptions(idContract: Number) {
    return this.http.get<any>(
      this.url + 'contract/' + idContract + '/subscriptions/',
      {}
    );
  }

  getSuscriptionsJuridical(idJuridicalPerson: Number) {
    return this.http.get<any>(
      this.url + 'juridical_person/' + idJuridicalPerson + '/subscriptions/',
      {}
    );
  }

  postSubscription(idContract: Number, subscription: any) {
    return this.http.post<any>(
      this.url + 'contract/' + idContract + '/subscriptions/',
      subscription
    );
  }
  getContractSuscription(idSubscription: string) {
    return this.http.get<any>(
      this.url + 'subscription/' + idSubscription + '/',
      {}
    );
  }

  deleteSuscription(
    idContract: Number,
    subscriptionId: string,
    monthEndDate?: string
  ) {
    if (monthEndDate) {
      return this.http.delete<any>(
        this.url +
          'contract/' +
          idContract +
          '/subscription/' +
          subscriptionId +
          '/?month_end_date=' +
          monthEndDate,
        {}
      );
    } else {
      return this.http.delete<any>(
        this.url + 'contract/' + idContract + '/subscription/' + subscriptionId,
        {}
      );
    }
  }

  updateSubscription({
    idContract,
    subscriptionId,
    price = null,
    email = null,
    name = null,
    nif = null,
    address = null,
    month_date_query = null,
    year_date_query = null,
    subscription_card_payment = null,
    bank_draft = null,
    is_transfer = null,
  }) {
    return this.http.patch<any>(
      this.url +
        'contract/' +
        idContract +
        '/subscription/' +
        subscriptionId +
        '/',
      {
        price: price,
        email: email,
        name: name,
        nif: nif,
        address: address,
        month_date_query: month_date_query,
        year_date_query: year_date_query,
        subscription_card_payment: subscription_card_payment,
        bank_draft: bank_draft,
        is_transfer: is_transfer,
      }
    );
  }

  createRecurrentPayment(idContract: Number, params: {}) {
    return this.http.post<SubscriptionCardPayment>(
      this.url + 'contract/' + idContract + '/recurrent_payments/',
      params
    );
  }

  getScheduledPaymentsExecutions(contractRecurrentPaymentId: String) {
    return this.http.get<any>(
      this.url +
        'contract_recurrent_payment/' +
        contractRecurrentPaymentId +
        '/scheduled_payments_executions/',
      {}
    );
  }

  storeCard(
    idContract: Number,
    card: Card,
    payment_id: string
  ): Observable<any> {
    return this.http.post<any>(this.url + 'user/card/', {
      contract_id: idContract,
      card: card,
      payment_id: payment_id,
    });
  }

  schedulePayment(idContract: Number, paymentId: string) {
    return this.http.put<any>(
      this.url +
        'contract/' +
        idContract +
        '/payment/' +
        paymentId +
        '/schedule/',
      {}
    );
  }

  authorizeScheduledPayment(
    invoice_id: number,
    payment_already_paid?: boolean,
    amount?: number
  ) {
    const params = {
      invoice_id: invoice_id,
      payment_already_paid: payment_already_paid,
      amount: amount,
    };
    return this.http.post<any>(
      this.url + 'contract_recurrent_payment/authorize_scheduled_payment/',
      params
    );
  }

  bankDraftBounced(invoice_id: number) {
    return this.http.post<any>(
      this.url + 'contract_recurrent_payment/bank_draft_bounced/',
      { invoice_id: invoice_id }
    );
  }
}
