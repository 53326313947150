/* SERVICIOS PARA ANGULAR */
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { globalApi } from './global';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractFilter, Contrato } from '../models/contrato';
import { JuridicalPerson } from '../models/constitution';
import { ContractSummary } from '../models/crm/crm';
import { getRegisterAnalytics } from '../models/register';
import { AuthService } from './auth.service';
import { ChangeResponsibleBody } from '../models/crm/change-responsible-body';

declare let gtag: Function;
declare let window: any;
@Injectable({
  providedIn: 'root',
})
export class ContratoService {
  public url: string;
  public token: {};
  constructor(
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private authService: AuthService
  ) {
    this.url = globalApi.url;
  }

  /*
    METODO PARA OBTENER EL contrato
    */
  getContracts(user_contracts?: boolean): Observable<Contrato[]> {
    if (user_contracts) {
      return this._http.get<Contrato[]>(
        this.url + 'contract/?user_contracts=true'
      );
    } else {
      return this._http.get<Contrato[]>(this.url + 'contract/');
    }
  }

  getCustomerContracts(customer_id): Observable<Contrato[]> {
    return this._http.get<Contrato[]>(
      this.url + 'contract_customer?customer_id=' + customer_id
    );
  }
  /*
    METODO PARA OBTENER EL contrato
    */
  getContrato(idContract): Observable<Contrato> {
    return this._http.get<Contrato>(this.url + 'contract/' + idContract + '/');
  }
  /*
    METODO PARA OBTENER EL contrato
    */
  updateContrato(idContract, contrato: Contrato): Observable<Contrato> {
    return this._http.put<Contrato>(
      this.url + 'contract/' + idContract + '/',
      contrato
    );
  }
  /*
    METODO PARA OBTENER EL contrato
    */
  patchContrato(idContract, contrato: Partial<Contrato>): Observable<Contrato> {
    return this._http.patch<Contrato>(
      this.url + 'contract/' + idContract + '/',
      contrato
    );
  }

  updateContractResponsible(
    updateResponsibleForm: Partial<ChangeResponsibleBody>,
    contractId: number
  ): Observable<Object> {
    let url = this.url + 'contract/' + contractId + '/update_responsible/';
    return this._http.patch(url, updateResponsibleForm);
  }

  createContract(contract: Contrato): Observable<any> {
    return this._http.post<any>(this.url + 'contract/', contract);
  }

  createOrUpdateCustomerExtraData(
    data: any,
    contractId: number
  ): Observable<any> {
    return this._http.put<any>(
      this.url + 'contract/' + contractId + '/formulario-lso/',
      data
    );
  }

  getCustomerExtraData(contractId: number): Observable<any> {
    return this._http.get<any>(
      this.url + 'contract/' + contractId + '/formulario-lso/'
    );
  }

  /*
    METODO PARA OBTENER LA constitución
    */
  getConstitucion(is_salable): Observable<JuridicalPerson[]> {
    return this._http.get<JuridicalPerson[]>(
      this.url + 'sell_society/?is_salable=' + is_salable
    );
  }

  afterCreateContract(contract) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };

    var cookiesAccepted = false;
    if (this.authService.getCookie('is_coockies_accepted')) {
      let cookies = JSON.parse(
        this.authService.getCookie('is_coockies_accepted')
      );
      if (cookies['marketing'] == 'yes' && cookies['mandatory'] == 'yes') {
        cookiesAccepted = true;
      }
    }

    if (contract.conversion_action_product) {
      this.googleAdsAfterCreateContract(contract);
    }

    if (
      getRegisterAnalytics(this._platformId)['source'] == 'tradedoubler' &&
      cookiesAccepted
    ) {
      this.tradedoublerAfterCreateContract(contract);
    }

    gtag('event', 'lead_conversion', {
      product_id: contract.product,
    });
  }

  googleAdsAfterCreateContract(contract) {
    gtag('js', new Date());

    gtag('config', 'AW-472317727');

    gtag('event', 'conversion', {
      send_to:
        'AW-472317727/' + contract.conversion_action_product.conversion_label,
      value: contract.conversion_action_product.value,
      currency: 'EUR',
      transaction_id: contract.id,
    });
  }

  tradedoublerAfterCreateContract(contract) {
    window._tfa = window._tfa || [];
    window._tfa.push({ notify: 'event', name: 'lead', id: 1564940 });

    const t = document.createElement('script');
    const f = document.getElementsByTagName('script')[0];
    const a = '//cdn.taboola.com/libtrc/unip/1564940/tfa.js';
    const x = 'tb_tfa_script';

    if (!document.getElementById(x)) {
      t.async = true;
      t.src = a;
      t.id = x;
      f.parentNode?.insertBefore(t, f);
    }
  }
}

@Injectable()
export class ReturnService {
  private query_params = new BehaviorSubject({});
  private pending_query_params = new BehaviorSubject({});

  constructor() {}

  cleanQueryParams() {
    this.query_params = new BehaviorSubject({});
  }

  cleanPendingQueryParams() {
    this.pending_query_params = new BehaviorSubject({});
  }

  fetchQueryparams(): Observable<any> {
    return this.query_params.asObservable();
  }

  fetchPendingQueryparams(): Observable<any> {
    return this.pending_query_params.asObservable();
  }

  sendQueryparams(returnQueryparams: ContractFilter) {
    this.query_params.next({ returnQueryparams: returnQueryparams });
  }

  sendPendingQueryparams(returnPendingQueryparams: ContractFilter) {
    this.pending_query_params.next({
      returnPendingQueryparams: returnPendingQueryparams,
    });
  }
}
