<header id="enleyHeader" [ngClass]="showMenu ? 'shadow-header' : ''">
  <div class="responsive-header">
    <div>
      <div
        [ngClass]="
          showMenu ? 'responsive container-header' : 'container-header'
        "
      >
        <div class="item-header icon">
          <div class="openMenuWrapper" (click)="showMenu = !showMenu">
            <div class="openMenuLine"></div>
            <div class="openMenuLine"></div>
            <div class="openMenuLine"></div>
          </div>
          <a
            [routerLink]="['/']"
            style="padding: 0px 10px"
            [routerLinkActive]="['active']"
          >
            <img
              src="assets/img/enley-servicios-legales-online.png"
              class="enley-logo"
              alt="Logo enley"
            />
          </a>
        </div>
        <div
          class="item-header dropdown-header-enley"
          (click)="showProductDropdown = !showProductDropdown"
          [ngClass]="showProductDropdown ? 'responsive-dropdown' : ''"
        >
          <div class="dropdown-title">
            <a
              class="dropdown-link-menu linkable-item-menu"
              href="#"
              (click)="(false)"
              i18n
              >Servicios</a
            ><span class="dropdown-arrow"></span>
          </div>
          <div class="dropdown-content">
            <ng-container *ngFor="let product of products; let i = index">
              <ng-container
                *ngIf="
                  product.product_description &&
                  product.product_description.name &&
                  product.url
                "
              >
                <a
                  [attr.aria-label]="product.product_description.name"
                  title="{{ product.product_description.name }}"
                  [routerLink]="[product.url]"
                  >{{ product.product_description.name }}</a
                >
              </ng-container>
            </ng-container>
          </div>
        </div>
        <a
          class="item-header"
          [routerLink]="['/quienes-somos']"
          [routerLinkActive]="['active']"
          i18n
          >Quiénes somos</a
        >
        <div
          class="item-header dropdown-header-enley"
          (click)="showBlogDropdown = !showBlogDropdown"
          [ngClass]="showBlogDropdown ? 'responsive-dropdown' : ''"
        >
          <div class="dropdown-title">
            <a class="dropdown-link-menu linkable-item-menu" href="/blog" i18n
              >Blog </a
            ><span class="dropdown-arrow" (click)="(false)"></span>
          </div>
          <div class="dropdown-content">
            <a href="/blog-categoria/familia" title="Famlia" i18n> Familia</a>
            <a href="/blog-categoria/sucesiones" title="Sucesiones">
              Sucesiones</a
            >
            <a href="/blog-categoria/empresa" title="Empresa" i18n> Empresa</a>
            <a href="/blog-categoria/inmobiliario" title="Inmobiliario" i18n>
              Inmobiliario</a
            >
            <a href="/blog-categoria/sobre-enley" title="Sobre enley" i18n
              >Sobre
              <span style="font-weight: 400 !important; font-family: Quicksand"
                >enley</span
              ></a
            >
            <a href="/blog-categoria/gestoria" title="Gestoría" i18n>
              Gestoría</a
            >
          </div>
        </div>
        <a
          class="item-header"
          [routerLink]="['/formulario-de-contacto']"
          [routerLinkActive]="['active']"
          i18n
          >Contacto</a
        >
      </div>
    </div>
    <div class="private-area">
      <ng-container *ngIf="!isLoggedIn || !hasPassword">
        <a [routerLink]="['/registro']" style="padding: 0">
          <span i18n>Área privada</span>
          <img
            class="img-account"
            style="padding-left: 2px"
            src="assets/img/account.svg"
            alt="area privada"
          />
        </a>
      </ng-container>
      <ng-container *ngIf="isLoggedIn && hasPassword">
        <div
          class="dropdown-header-enley"
          style="padding-bottom: 11px; padding-top: 11px; padding-left: 30px"
        >
          <div class="initials">
            <div class="dropdown-title">
              <a
                class="link-no-hover"
                [href]="['/area-privada']"
                style="padding: 0px; color: white"
                >{{ name_initials }}</a
              >
            </div>
            <div
              class="dropdown-content dropdown-initials"
              style="margin-top: 30px; margin-left: 30px"
            >
              <a
                class="dropdown-my-account"
                *ngIf="!isAdmin"
                [href]="['area-privada']"
                >Area privada</a
              >
              <a
                class="dropdown-my-account"
                *ngIf="isAdmin"
                [routerLink]="['/backoffice/crm']"
                >Backoffice</a
              >
              <a
                class="dropdown-my-account"
                *ngIf="isAdmin"
                [routerLink]="['casos-admin']"
                >Casos</a
              >
              <a class="dropdown-my-account" (click)="logout()">Logout</a>
            </div>
          </div>
        </div>
        <div class="bell-box" (click)="showAlerts($event)">
          <ng-container *ngIf="notificationsCounter">
            <div class="red-notification">
              <span class="text-position-alert">{{
                notificationsCounter
              }}</span>
            </div>
          </ng-container>
        </div>
        <div
          *ngIf="showNotifications === true && hasPassword"
          (click)="dontToggle($event)"
        >
          <div
            class="liNotification"
            *ngFor="let obj of notifications; let i = index"
            [ngStyle]="{
              'background-color': obj.viewed ? '#fff' : '#00bed6',
              'border-color': obj.viewed ? '#e5e5e5' : '#00bed6'
            }"
          >
            <div class="pagoNotificationHeader">
              <p
                class="pagoNotificationTitle"
                [ngStyle]="{color: obj.viewed ? '#e5e5e5' : '#fff'}"
              >
                {{ obj.product_subtype | titlecase }}
              </p>
              <div class="closeNotificationBtnPosition">
                <img
                  src="assets/img/close-notification.svg"
                  alt="Cerrar"
                  (click)="deleteNotificacion(obj.id, obj.contract_id)"
                  width="16px"
                />
              </div>
            </div>
            <div
              class="pagoBtn"
              [ngStyle]="{
                'background-color': obj.viewed ? '#e5e5e5' : '#00788e'
              }"
            >
              <a class="pagoBtnTextStyle" [routerLink]="[obj.url]">
                {{
                  utilsService.transformTextPrice(
                    obj.text_button,
                    obj.product_price
                  )
                }}€
              </a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</header>

<!-- Server Error pop up -->
<div class="loading" *ngIf="error500">
  <div class="serverErrorDiv">
    <span class="material-icons closePopUp" (click)="closePopUpServerError()">
      clear
    </span>
    <p class="closePopUpTitle">¡Lo sentimos, ha ocurrido un error!</p>
    <p class="closePopUpText">
      Ponte en contacto con nosotros en el <br />
      <a href="tel:91 417 25 85"><b>91 417 25 85</b></a> o
      <a href="mailto:info@enley.com"><b>info&#64;enley.com</b></a
      >. Gracias
    </p>
  </div>
</div>
<!--End Server Error pop up -->
