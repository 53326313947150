import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-floating-button-contract',
  templateUrl: './floating-button-contract.component.html',
  styleUrls: ['./floating-button-contract.component.css'],
})
export class FloatingButtonContractComponent {
  @Input() public contract_id: number;

  constructor(private _router: Router) {}

  goToPayment() {
    this._router.navigate([
      '/contrato/' + this.contract_id.toString() + '/pre-pago/1',
    ]);
  }
}
