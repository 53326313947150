import { isPlatformBrowser } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from './app/services/auth.service';
import { globalApi } from './app/services/global';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public url: string;
  public API_NOT_INTERCEPT: string[];

  state: RouterStateSnapshot;
  constructor(
    public authService: AuthService,
    public router: Router,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    this.url = globalApi.url;
    this.state = this.router.routerState.snapshot;
    this.API_NOT_INTERCEPT = [
      this.url + '/registration/account-confirm-email/',
      this.url + 'product/',
      'https://192.168.33.12:80/enley/export_contracts/',
    ];
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = this.authService.getJwtToken();
    if (
      token != undefined &&
      token != null &&
      token !== 'undefined' &&
      this.API_NOT_INTERCEPT.indexOf(request.url) < 0
    ) {
      request = this.addToken(request, this.authService.getJwtToken());
    }

    request = this.addLanguage(request, 'es');

    request = request.clone({
      body: this.clean(request.body),
    });
    

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next, error);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private addLanguage(request: HttpRequest<any>, language: string) {
    return request.clone({
      setHeaders: {
        'Accept-Language': 'es',
      },
    });
  }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, error) {
    if (request.url.includes('/enley/token/refresh/')) {
        this.authService.doLogoutUser();
        this.isRefreshing = false;
        return throwError(error);
    }

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access);
          return next.handle(this.addToken(request, token.access));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((access_token) => {
          return next.handle(this.addToken(request, access_token));
        })
      );
    }
  }

  //Si un campo es null no lo enviamos, en el backend no diferenciamos entre null y undefined.
  clean(obj: Object) {
    for (let item in obj) {
      let propName: any = item;
      if (obj[propName] instanceof Object) {
        this.clean(obj[propName]);
      } else {
        if (obj[propName] == null || obj[propName] == undefined) {
          delete obj[propName];
        }
      }
    }
    return obj;
  }
}
