import {
  Component,
  OnInit,
  HostListener,
  Inject,
  PLATFORM_ID,
  Renderer2,
  Optional,
} from '@angular/core';
import {
  APP_BASE_HREF,
  Location,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductsID } from 'src/app/models/contrato';
import { Product } from 'src/app/models/products';
import { ContentType, setRegisterAnalytics } from 'src/app/models/register';
import { enleySchema, productsToServices } from 'src/app/schemaOrg';
import { HeadService } from 'src/app/services/head.service';
import { ProductsServices } from 'src/app/services/products.services';
import { REFERER } from '../header/url';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [HeadService],
})
export class HomeComponent implements OnInit {
  public innerWidth: number;
  private script: HTMLScriptElement;
  public divorceProduct: Product;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _router: Router,
    private route: ActivatedRoute,
    private productServices: ProductsServices,
    renderer: Renderer2,
    headService: HeadService,
    location: Location,
    private analyticsService: AnalyticsService
  ) {
    const head = headService.forRenderer(renderer);
    head.createElement('link', {
      rel: 'preload',
      as: 'image',
      href: 'assets/img/servicios-legales-online-mobile.webp',
    });
    if (isPlatformServer(this._platformId)) {
      productServices.getAvailableProducts().subscribe((products) => {
        const services = [...productsToServices(products, location)];
        head.createElement('script', {
          type: 'application/ld+json',
          [HeadService.ELEMENT_CONTENT]: JSON.stringify(enleySchema(services)),
        });
      });
    }
  }

  ngOnInit(): void {
    setRegisterAnalytics(
      this.route,
      this._platformId,
      this.analyticsService.getReferer(),
      ContentType.HOME
    );
    this.getDivorceProduct();
  }

  getDivorceProduct() {
    this.productServices
      .getProduct(ProductsID.divorce_product_id)
      .subscribe((productResp) => {
        this.divorceProduct = productResp;
      });
  }

  @HostListener('window:resize', ['$event'])
  onesize(event) {
    this.innerWidth = window.innerWidth;
  }

  onClick() {
    let x = document.querySelector('#ofrecemos');
    if (x) {
      x.scrollIntoView({ behavior: 'smooth' });
    }
  }

  moreInfo() {
    this._router.navigate(['/politica-de-cookies']);
  }
}
