import { Injectable } from '@angular/core';
import { InvoiceFilter } from 'src/app/models/invoice';
import { FormBuilder } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class FilterInvoiceFormService {
  public filterInvoiceForm = this.formBuilder.group<InvoiceFilter>({
    query: '',
    payment_status: '',
    year: undefined,
    month: undefined,
    product: undefined,
    executed: '',
    contract_id: undefined,
    amount: undefined,
    payment_type: '',
  });

  constructor(private formBuilder: FormBuilder) {}
}
