import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contrato } from './contrato';
import { NationalId } from './user';

export class Register {
  public password1: string;
  public password2: string;
  public phone_number: string;
  public phone_number_temp?: string;
  public person_type: string;
  public gdpr_accepted: boolean; // politica
  public enley_accepted: boolean; // comunicaciones
  public invitation_key: string;
  public register_type: string;
  public first_name: string;
  public email: string;
  public last_name: string;
  public second_last_name?: string;
  public national_id?: NationalId;
  public register_analytics: RegisterAnalytics;
  public non_registered_user: boolean;
  public new_password1?: string;
  public new_password2?: string;
  public send_confirmation: boolean;
  public product_id?: number;
  public date_joined?: Date;
  public contract?: Contrato;
  public username: string;
  constructor() {}
}
export class Resend {
  constructor(public email2: string) {}
}

export class RegisterAnalytics {
  public source: string;
  public medium: string;
  public term: string;
  public campaign: string;
  public content: string;
}

export enum ContentType {
  BLOG = 'blog',
  CONTACT_FORM = 'contact_form',
  REGISTER = 'register',
  PRODUCT_HOME = 'product_home',
  HOME = 'home',
  PREVIOUS_FORM = 'previous_form',
  CRM = 'crm',
}

export function setRegisterAnalytics(_route, _platformId, referer, content) {
  _route.queryParams.subscribe((params) => {
    let register_analytics = new RegisterAnalytics();
    register_analytics.campaign = params['utm_campaign'];
    register_analytics.content = params['utm_content'];
    register_analytics.source = params['utm_source'];
    register_analytics.medium = params['utm_medium'];
    register_analytics.term = params['utm_term'];
    let register_analytics_old = getRegisterAnalytics(_platformId);
    if (isPlatformBrowser(_platformId)) {
      if (register_analytics.campaign || register_analytics.source) {
        localStorage.setItem(
          'register_analytics',
          JSON.stringify(register_analytics)
        );
      } else if (
        !register_analytics_old.campaign &&
        !register_analytics_old.content
      ) {
        register_analytics.medium = 'organic';
        if (referer) {
          register_analytics.source = referer.substring(0, 39);
        } else {
          register_analytics.source = 'direct';
        }
        register_analytics.content = content;
        localStorage.setItem(
          'register_analytics',
          JSON.stringify(register_analytics)
        );
      }
    }
  });
}

export function getRegisterAnalytics(_platformId): RegisterAnalytics {
  let register_analytics = new RegisterAnalytics();
  if (
    isPlatformBrowser(_platformId) &&
    <RegisterAnalytics>JSON.parse(localStorage.getItem('register_analytics'))
  ) {
    register_analytics = <RegisterAnalytics>(
      JSON.parse(localStorage.getItem('register_analytics'))
    );
  } else {
    register_analytics = new RegisterAnalytics();
  }
  return register_analytics;
}

export function getRegisterAnalyticsURL(route): Observable<RegisterAnalytics> {
  return route.queryParams.pipe(
    map((params) => {
      let register_analytics = new RegisterAnalytics();
      register_analytics.campaign = params['utm_campaign'];
      register_analytics.content = params['utm_content'];
      register_analytics.source = params['utm_source'];
      register_analytics.medium = params['utm_medium'];
      register_analytics.term = params['utm_term'];
      return register_analytics;
    })
  );
}
