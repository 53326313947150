<label>
  <span class="input-header">{{ title }}</span>
  <input
    type="text"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    (blur)="onBlur()"
    #textInput="ngModel"
    name="value"
  />
  <small
    *ngIf="
      control.errors != null &&
      control.errors.required &&
      (control.touched || form.submitted)
    "
    >Completa este campo.</small
  >
  <small
    *ngIf="
      control.errors != null &&
      control.errors &&
      (control.touched || form.submitted)
    "
    >{{ control.errors.message }}</small
  >
</label>
