<!-- HERO SECTION -->
<section id="hero" class="heroHomeEnley">
  <div class="logo">
    <img
      id="imgHome"
      class="logoStyle"
      src="assets/img/enley-servicios-legales-online.png"
      alt="Consulta abogados online"
      height="66"
      width="193"
    />
  </div>
  <h1
    i18n="title"
    id="titlehomeenley"
    class="display-3 titleFontMain titlePadding"
  >
    Abogados online
  </h1>
  <div class="Nuestros ourHomeButton">
    <button
      (click)="onClick()"
      i18n
      class="btn btn-custom classHover bordeado paddingButton-custom btnNuestroServicioStyle"
    >
      NUESTROS SERVICIOS
    </button>
  </div>
  <div class="overlay headerImageCentered">
    <picture class="headerImageWidth">
      <source
        [srcset]="'assets/img/servicios-legales-online.webp'"
        media="(min-width: 500px)"
      />
      <source [srcset]="'assets/img/servicios-legales-online-mobile.webp'" />
      <img
        i18n
        class="headerImageWidth"
        [src]="'assets/img/servicios-legales-online-mobile.webp'"
        alt="Servicios legales online al alcance de todos"
        height="541"
        width="1190"
      />
    </picture>
  </div>
</section>
<!-- SECTION FUNCTIONA -->
<section id="funciona">
  <div id="top" style="padding-bottom: 0px !important">
    <h2
      i18n
      class="porqueEnleyTitleText"
      style="padding-bottom: 20px !important"
    >
      Por qué <span style="font-family: Quicksand"><b>enley</b></span>
    </h2>
    <p i18n class="porqueEnleyTitleText2">
      Disfruta de los mismos servicios legales de un despacho de abogados, pero
      ahorrándote costes y trámites innecesarios. Así de sencillo.
    </p>
  </div>
  <div class="container-fluid">
    <div
      id="hideEnley"
      class="row text-center hideEnleyHomeCar centerPorqueEnley"
    >
      <div
        id="carouselExampleControls1"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active pc-version">
            <div id="" class="row text-center" style="justify-content: center">
              <div class="col-md-2">
                <img
                  [src]="
                    'assets/img/iconhome/enley-servicios-legales-online.webp'
                  "
                  alt="Abogados baratos"
                  loading="lazy"
                  height="150"
                  width="150"
                  class="slide-three"
                />
                <p
                  i18n
                  class="card-text parrafocarrosuel porqueEnleyTextStyle4 pc-text"
                >
                  Estés donde estés
                </p>
                <p
                  i18n
                  class="card-text parrafocarrosuel porqueEnleyTextStyle5 pc-text"
                >
                  Podrás acceder a nuestros servicios siempre que lo necesites
                </p>
              </div>
            </div>
            <div id="" class="row text-center" style="justify-content: center">
              <div class="col-md-2">
                <img
                  [src]="
                    'assets/img/iconhome/abogados-colegiados-expertos.webp'
                  "
                  alt="Abogados colegiados supervisan todo el proceso"
                  loading="lazy"
                  height="150"
                  width="150"
                  class="slide-three"
                />
                <p
                  i18n
                  class="card-text parrafocarrosuel porqueEnleyTextStyle4 pc-text"
                >
                  Con todas las garantías
                </p>
                <p
                  i18n
                  class="card-text parrafocarrosuel porqueEnleyTextStyle5 pc-text"
                >
                  Nuestros abogados supervisan todo el proceso y están en
                  contacto contigo en todo momento
                </p>
              </div>
            </div>
            <div id="" class="row text-center" style="justify-content: center">
              <div class="col-md-2">
                <img
                  [src]="
                    'assets/img/iconhome/enley-plataforma-digital-online.webp'
                  "
                  alt="Digitalizamos los procesos legales online"
                  loading="lazy"
                  height="150"
                  width="150"
                  class="slide-three"
                />
                <p
                  i18n
                  class="card-text parrafocarrosuel porqueEnleyTextStyle4 pc-text"
                >
                  Plataforma online
                </p>
                <p
                  i18n
                  class="card-text parrafocarrosuel porqueEnleyTextStyle5 pc-text"
                >
                  Participas en el proceso y subes la información necesaria
                  directamente a nuestra plataforma
                </p>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div id="" class="row text-center" style="justify-content: center">
              <div class="col-md-2 first-element">
                <img
                  [src]="
                    'assets/img/iconhome/enley-optimizacion-servicios-legales.webp'
                  "
                  alt="Nuevas tecnologias optimizan servicios legales"
                  loading="lazy"
                  height="150"
                  width="150"
                  class="slide-two"
                />
                <p
                  i18n
                  class="card-text parrafocarrosuel porqueEnleyTextStyle4 pc-text"
                >
                  Digitalizamos los procesos
                </p>
                <p
                  i18n
                  class="card-text parrafocarrosuel porqueEnleyTextStyle5 pc-text"
                >
                  Utilizamos las nuevas tecnologías para optimizar los servicios
                  legales que ofrecemos
                </p>
              </div>
            </div>
            <div id="" class="row text-center" style="justify-content: center">
              <div class="col-md-2">
                <img
                  [src]="'assets/img/iconhome/precio-economico.webp'"
                  alt="Precio cerrado con todo incluido"
                  loading="lazy"
                  height="150"
                  width="150"
                  class="slide-two"
                />
                <p
                  i18n
                  class="card-text parrafocarrosuel porqueEnleyTextStyle4 pc-text"
                >
                  Precio
                </p>
                <p
                  i18n
                  class="card-text parrafocarrosuel porqueEnleyTextStyle5 pc-text"
                >
                  En todo momento sabes el coste del servicio, sin sorpresas
                </p>
              </div>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselExampleControls1"
          role="button"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon2" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleControls1"
          role="button"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon2" aria-hidden="false"></span>
          <span class="visually-hidden">Next</span>
        </a>
      </div>
      <h2 i18n class="porqueEnleyTitleText3">
        Abogados colegiados a un clic de ti
      </h2>
    </div>
    <!-- FIN CAROSUEL POR QUE ENLEY -->
    <div
      id="carouselExampleControls"
      class="carousel slide"
      data-bs-ride="carousel"
      style="display: none"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div id="" class="row text-center" style="justify-content: center">
            <div class="col-md-2">
              <img
                [src]="
                  'assets/img/iconhome/enley-servicios-legales-online.webp'
                "
                alt="Abogados baratos"
                loading="lazy"
                height="150"
                width="150"
              />
              <p i18n class="card-text parrafocarrosuel porqueEnleyTextStyle4">
                Estés donde estés
              </p>
              <p i18n class="card-text parrafocarrosuel porqueEnleyTextStyle5">
                Podrás acceder a nuestros servicios siempre que lo necesites
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div id="" class="row text-center" style="justify-content: center">
            <div class="col-md-2">
              <img
                [src]="'assets/img/iconhome/abogados-colegiados-expertos.webp'"
                alt="Abogados colegiados supervisan todo el proceso"
                loading="lazy"
                height="150"
                width="150"
              />
              <p i18n class="card-text parrafocarrosuel porqueEnleyTextStyle4">
                Con todas las garantías
              </p>
              <p i18n class="card-text parrafocarrosuel porqueEnleyTextStyle5">
                Nuestros abogados supervisan todo el proceso y están en contacto
                contigo en todo momento
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div id="" class="row text-center" style="justify-content: center">
            <div class="col-md-2">
              <img
                [src]="
                  'assets/img/iconhome/enley-plataforma-digital-online.webp'
                "
                alt="Digitalizamos los procesos legales online"
                loading="lazy"
                height="150"
                width="150"
              />
              <p i18n class="card-text parrafocarrosuel porqueEnleyTextStyle4">
                Plataforma online
              </p>
              <p i18n class="card-text parrafocarrosuel porqueEnleyTextStyle5">
                Participas en el proceso y subes la información necesaria
                directamente a nuestra plataforma
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div id="" class="row text-center" style="justify-content: center">
            <div class="col-md-2">
              <img
                [src]="
                  'assets/img/iconhome/enley-optimizacion-servicios-legales.webp'
                "
                alt="Nuevas tecnologias optimizan servicios legales"
                loading="lazy"
                height="150"
                width="150"
              />
              <p i18n class="card-text parrafocarrosuel porqueEnleyTextStyle4">
                Digitalizamos los procesos
              </p>
              <p i18n class="card-text parrafocarrosuel porqueEnleyTextStyle5">
                Utilizamos las nuevas tecnologías para optimizar los servicios
                legales que ofrecemos
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div id="" class="row text-center" style="justify-content: center">
            <div class="col-md-2">
              <img
                [src]="'assets/img/iconhome/precio-economico.webp'"
                alt="Precio cerrado con todo incluido"
                loading="lazy"
                height="150"
                width="150"
              />
              <p i18n class="card-text parrafocarrosuel porqueEnleyTextStyle4">
                Precio
              </p>
              <p i18n class="card-text parrafocarrosuel porqueEnleyTextStyle5">
                En todo momento sabes el coste del servicio, sin sorpresas
              </p>
            </div>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon2" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon2" aria-hidden="false"></span>
        <span class="visually-hidden">Next</span>
      </a>
      <h2 i18n class="porqueEnleyTitleText3" style="text-align: center">
        Abogados colegiados a un clic de ti
      </h2>
    </div>

    <!-- FIN CAROSUEL POR QUE ENLEY -->
  </div>
</section>
<!-- SECTION ofrecemos -->
<app-products-description></app-products-description>

<!-- SECTION texto Enley -->
<section id="textoEnley" class="sectionText">
  <div class="row text-center hideEnleyHomeCar centerPorqueEnley">
    <p class="porqueEnleyTitleText2 textEnley">
      En <span style="font-family: Quicksand">enley</span> nuestro objetivo es
      <span style="font-weight: bold">democratizar los servicios legales</span>,
      para que cualquier persona pueda ser asesorada por abogados profesionales
      sin importar dónde esté o de cuál sea su situación. <br /><br />Para
      conseguirlo, utilizamos la tecnología, lo que nos permite
      <span style="font-weight: bold"
        >automatizar los procesos legales y hacerlos más rápidos, ágiles</span
      >, económicos y accesibles, sin renunciar a la profesionalidad de un
      bufete de abogados.
    </p>
  </div>
</section>

<!-- SECTION  nosotros -->
<ng-container *ngIf="divorceProduct">
  <app-product-reviews
    [product]="divorceProduct"
    backgroundColor="gray"
    cardColor="white"
  ></app-product-reviews>
</ng-container>
<app-faqs></app-faqs>
